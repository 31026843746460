import AppButton, { AppButtonVariant } from "Atomic/atoms/Button/AppButton";
import { BaseTypes } from "Components/types/helpers/Slide";
import { getSlidePropertiesByType } from "Components/types/helpers/slideTypeHelper";
import { testIds } from "Scripts/cypressTestIds";
import { DataElPosition, DataElScreen } from "Scripts/measurementsGAHelper";
import { numberWithCommas } from "Scripts/stringFormatter";
import { FeatureState, PlanInfo } from "Types/featureTypes";
import { SlideList, SlideProperties } from 'Types/presentationTypes';
import { SlideTypes } from "Types/slideTypes";
import React, { ReactElement, useMemo } from "react";
import { RootStateOrAny, useSelector } from "react-redux";
import { getSlideProperties } from '../components/types/helpers/slideTypeHelper';
import { useAI } from "./useAI";
import { useLayout } from "./useLayout";
import { useTranslations } from "./useTranslations";

export interface FeaturePropsList {
	[key: string]: FeatureProps
}

export interface FeatureProps {
	friendlyName: string;
	/** Appends 'is a premium feature' to the friendlyName */
	addPremiumSuffix?: boolean,
	hideUpgradeButton?: boolean,
	explanationText: string | ReactElement;
	continueButtonText?: string|ReactElement;
	templateFunction?: ReactElement;
	imagePreviewUrl?: string;
}

/** API Features */
export enum Features {
	MULTIPLE_VOTE = 'MULTIPLE_VOTE',
	VOTE_SMS = 'VOTE_SMS',
	AUDIENCE_IDENTIFICATION = 'AUDIENCE_IDENTIFICATION',
	EDIT_RESPONSE_CODE = 'EDIT_RESPONSE_CODE',
	MESSAGE_FILTER = 'MESSAGE_FILTER',
	SURVEY = 'SURVEY',
	BACKGROUND_MUSIC = 'BACKGROUND_MUSIC',
	LEADERBOARDS_BETWEEN_SLIDES = 'LEADERBOARDS_BETWEEN_SLIDES',
	EDIT_VOTE_ANSWERS = 'EDIT_VOTE_ANSWERS',
	UNLIMITED_SLIDES = 'UNLIMITED_SLIDES',
	RESPONSE_WEBSITE_EMOJIS = 'RESPONSE_WEBSITE_EMOJIS',
	WEB_PRESENTATION_STYLES = 'WEB_PRESENTATION_STYLES',
	WEB_SHOW_COMPANY_LOGO = 'WEB_SHOW_COMPANY_LOGO',
	ADD_USERS_TO_LICENSE = 'ADD_USERS_TO_LICENSE',
	ADD_MANAGERS_TO_LICENSE = 'ADD_MANAGERS_TO_LICENSE',
	ADD_ADMINS_TO_LICENSE = 'ADD_ADMINS_TO_LICENSE',
	REQUEST_LICENSE_ACCESS = 'REQUEST_LICENSE_ACCESS',
	EXPORT_PRESENTATION_RESULTS_PDF = 'EXPORT_PRESENTATION_RESULTS_PDF',
	EXPORT_PRESENTATION_RESULTS_EXCEL = 'EXPORT_PRESENTATION_RESULTS_EXCEL',
	POINTS_QUESTION = 'POINTS_QUESTION',
	SHARE_PRESENTATIONS = 'SHARE_PRESENTATIONS',
	ANALYTICS_DASHBOARD = 'ANALYTICS_DASHBOARD',
	GENERATE_AI_PRESENTATIONS = 'GENERATE_AI_PRESENTATIONS',
	EXPORT_PRESENTATION_POWERPOINT = 'EXPORT_PRESENTATION_POWERPOINT',
	ALL_AI_LANGUAGES = 'ALL_AI_LANGUAGES',
	UNLIMITED_AI_SLIDES = 'UNLIMITED_AI_SLIDES',
	CUSTOM_PRESENTATION_LOGO = 'CUSTOM_PRESENTATION_LOGO',
	GENERATE_AI_CONCLUSION = 'GENERATE_AI_CONCLUSION',
	PLAY_PRESENTATION = 'PLAY_PRESENTATION',
}

/** Not actual features, but we 'derive' these based on relevant values or events */
export enum DerivedFeatures {
	UNLIMITED_PARTICIPANTS = 'UNLIMITED_PARTICIPANTS',
	REACHED_MAX_USERS = 'REACHED_MAX_USERS',
	REACHED_MAX_PARTICIPANTS = 'REACHED_MAX_PARTICIPANTS',
	REACHED_MAXIMUM_RESULTS = 'REACHED_MAXIMUM_RESULTS',
}

const LimitReachedText = (): ReactElement => {

    const { translatePlaceholder } = useTranslations();

	const planInfo = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).planInfo) || {};

	return (
		<>
		{
			translatePlaceholder('FE_UNLIMITED_SLIDES', {
				maxQuizQuestionsCount: planInfo?.maxQuizQuestionsCount,
				maxQuestionsCount: planInfo?.maxQuestionsCount,
			})
		}
		</>
	);
}

const MaxParticipantsReachedText = () => {

	const planInfo = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).planInfo) || {};

    return (
        <>
            Oops! You've reached the maximum amount of participants.
            <br/>
            You can still continue this presentation with the permitted amount of participants ({numberWithCommas(planInfo.audienceSize)}).
        </>
    )
}

const ContinueInEnglish = () => {

    const {
		translatePlaceholder
	} = useTranslations();

	const {
		setShowMissingFeature
	} = useLayout();

	const {
        setChosenLanguage,
    } = useAI();

	return (
		<AppButton
			onClick={() => {
				setChosenLanguage('English');
				setShowMissingFeature(null);
			}}
			as={AppButtonVariant.Outlined}
			data-elscreen={DataElScreen.AIStepper}
			data-elaction="continue_working"
			data-elposition={DataElPosition.FeaturePopup}
			data-testid={testIds.MISSING_FEATURE_EXTRA_BUTTON}
		>
			{translatePlaceholder('CONTINUE_IN_ENGLISH')}
		</AppButton>
	)
}

export const useFeatures = () => {

	const { translatePlaceholder } = useTranslations();

	const availableFeatures = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).availableFeatures) || [];

	const planInfo = useSelector((state: RootStateOrAny) => (state.featureReducer as FeatureState).planInfo);

	const isFeatureAvailable = (featureName: Features) : boolean => {

        return (availableFeatures || []).includes(featureName);
    }

	const getFeatureFriendlyName = (feature: Features|DerivedFeatures): string => {

		const featureKeys = Object.keys(featureProperties);

		return featureProperties?.[feature]?.friendlyName || feature;
	}

	const getFeatureProps = (featureName: string): FeatureProps | boolean => {

		const featureKeys = Object.keys(featureProperties);

		if (featureKeys.includes(featureName)) {

			return featureProperties[featureName];
		}

		return false;
	}


    const getNumberOfQuestions = (slideList : SlideList) : Array<number> => {

        let quizQuestions = 0;
        let otherQuestions = 0;

        Object.keys(slideList).forEach(slideKey => {

            const slideProperties: SlideProperties = getSlideProperties(slideList[slideKey]);

            if ( slideProperties.baseType === BaseTypes.Vote && slideProperties.text.alias == 'quiz' ) {
                    quizQuestions++
            } else {
                otherQuestions++
            }
        })

        return [quizQuestions, otherQuestions];
    }

    const hasExceededSlideLimit = (slideList : SlideList) : boolean => {

        // Check if planinfo is available
        if ( !planInfo ) {
            return false;
        }

        // Check if planinfo specifies unlimited slides allowed
        if ( planInfo?.maxQuestionsCount === -1 && planInfo?.maxQuizQuestionsCount == -1 ) {
            return false;
        }

        const [ numberOfQuizQuestions, numberOfOtherQuestions ] = getNumberOfQuestions(slideList);

        return (
            numberOfQuizQuestions > planInfo?.maxQuizQuestionsCount ||
            numberOfOtherQuestions > planInfo?.maxQuestionsCount
        );
    }

    /* Most of the explanation test is from the sendsteps.com pricing page*/
    const featureProperties: FeaturePropsList = useMemo(() => ({

		[DerivedFeatures.REACHED_MAXIMUM_RESULTS]: {
			friendlyName: translatePlaceholder('FT_' + DerivedFeatures.REACHED_MAXIMUM_RESULTS),
			explanationText: translatePlaceholder('FE_' + DerivedFeatures.REACHED_MAXIMUM_RESULTS),
			addPremiumSuffix: true,
		},
		[DerivedFeatures.REACHED_MAX_USERS]: {
			friendlyName: translatePlaceholder('FT_' + DerivedFeatures.REACHED_MAX_USERS),
			explanationText: translatePlaceholder('FE_' + DerivedFeatures.REACHED_MAX_USERS),
			hideUpgradeButton: true,
		},
		[DerivedFeatures.UNLIMITED_PARTICIPANTS]: {
			friendlyName: translatePlaceholder('FT_' + DerivedFeatures.UNLIMITED_PARTICIPANTS),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + DerivedFeatures.UNLIMITED_PARTICIPANTS),
		},
		[DerivedFeatures.REACHED_MAX_PARTICIPANTS]: {
			friendlyName: translatePlaceholder('FT_' + DerivedFeatures.REACHED_MAX_PARTICIPANTS),
			addPremiumSuffix: true,
			explanationText: <MaxParticipantsReachedText />,
			continueButtonText: translatePlaceholder('BTN_CONTINUE'),
		},
		[Features.POINTS_QUESTION]: {
			friendlyName: translatePlaceholder('FT_' + Features.POINTS_QUESTION),
			addPremiumSuffix: false,
			explanationText: translatePlaceholder('FE_' + Features.POINTS_QUESTION),
			continueButtonText: translatePlaceholder('KEEP_WORKING_ON_MY_PRESENTATION'),
		},
		[Features.MULTIPLE_VOTE]: {
			friendlyName: translatePlaceholder('FT_' + Features.MULTIPLE_VOTE),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.MULTIPLE_VOTE),
		},
		[Features.VOTE_SMS]: {
			friendlyName: translatePlaceholder('FT_' + Features.VOTE_SMS),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.VOTE_SMS),
		},
		[Features.AUDIENCE_IDENTIFICATION]: {
			friendlyName: translatePlaceholder('FT_' + Features.AUDIENCE_IDENTIFICATION),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.AUDIENCE_IDENTIFICATION),
		},
		[Features.EDIT_RESPONSE_CODE]: {
			friendlyName: translatePlaceholder('FT_' + Features.EDIT_RESPONSE_CODE),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.EDIT_RESPONSE_CODE),
		},
		[Features.MESSAGE_FILTER]: {
			friendlyName: translatePlaceholder('FT_' + Features.MESSAGE_FILTER),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.MESSAGE_FILTER),
		},
		[Features.SURVEY]: {
			friendlyName: translatePlaceholder('FT_' + Features.SURVEY),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.SURVEY),
		},
		[Features.BACKGROUND_MUSIC]: {
			friendlyName: translatePlaceholder('FT_' + Features.BACKGROUND_MUSIC),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.BACKGROUND_MUSIC),
		},
		[Features.LEADERBOARDS_BETWEEN_SLIDES]: {
			friendlyName: translatePlaceholder('FT_' + Features.LEADERBOARDS_BETWEEN_SLIDES),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.LEADERBOARDS_BETWEEN_SLIDES),
		},
		[Features.EDIT_VOTE_ANSWERS]: {
			friendlyName: translatePlaceholder('FT_' + Features.EDIT_VOTE_ANSWERS),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.EDIT_VOTE_ANSWERS),
		},
		[Features.UNLIMITED_SLIDES]: {
			friendlyName: translatePlaceholder('FT_' + Features.UNLIMITED_SLIDES),
			addPremiumSuffix: false,
			explanationText: <LimitReachedText />,
			continueButtonText: translatePlaceholder('KEEP_WORKING_ON_MY_PRESENTATION'),
		},
		[Features.RESPONSE_WEBSITE_EMOJIS]: {
			friendlyName: translatePlaceholder('FT_' + Features.RESPONSE_WEBSITE_EMOJIS),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.RESPONSE_WEBSITE_EMOJIS),
		},
		[Features.WEB_PRESENTATION_STYLES]: {
			friendlyName: translatePlaceholder('FT_' + Features.WEB_PRESENTATION_STYLES),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.WEB_PRESENTATION_STYLES),
		},
		[Features.WEB_SHOW_COMPANY_LOGO]: {
			friendlyName: translatePlaceholder('FT_' + Features.WEB_SHOW_COMPANY_LOGO),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.WEB_SHOW_COMPANY_LOGO),
		},
		[Features.ADD_USERS_TO_LICENSE]: {
			friendlyName: translatePlaceholder('FT_' + Features.ADD_USERS_TO_LICENSE),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.ADD_USERS_TO_LICENSE),
		},
		[Features.ADD_MANAGERS_TO_LICENSE]: {
			friendlyName: translatePlaceholder('FT_' + Features.ADD_MANAGERS_TO_LICENSE),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.ADD_MANAGERS_TO_LICENSE),
		},
		[Features.ADD_ADMINS_TO_LICENSE]: {
			friendlyName: translatePlaceholder('FT_' + Features.ADD_ADMINS_TO_LICENSE),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.ADD_ADMINS_TO_LICENSE),
		},
		[Features.REQUEST_LICENSE_ACCESS]: {
			friendlyName: translatePlaceholder('FT_' + Features.REQUEST_LICENSE_ACCESS),
			addPremiumSuffix: false,
			hideUpgradeButton: true,
			explanationText: translatePlaceholder('FE_' + Features.REQUEST_LICENSE_ACCESS),
		},
		[Features.EXPORT_PRESENTATION_RESULTS_PDF]: {
			friendlyName: translatePlaceholder('FT_' + Features.EXPORT_PRESENTATION_RESULTS_PDF),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE' + Features.EXPORT_PRESENTATION_RESULTS_PDF),
		},
		[Features.EXPORT_PRESENTATION_RESULTS_EXCEL]: {
			friendlyName: translatePlaceholder('FT_' + Features.EXPORT_PRESENTATION_RESULTS_EXCEL),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.EXPORT_PRESENTATION_RESULTS_EXCEL),
		},
		[Features.SHARE_PRESENTATIONS]: {
			friendlyName: translatePlaceholder('FT_' + Features.SHARE_PRESENTATIONS),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.SHARE_PRESENTATIONS),
		},
		[Features.GENERATE_AI_PRESENTATIONS]: {
			friendlyName: translatePlaceholder('FT_' + Features.GENERATE_AI_PRESENTATIONS),
			addPremiumSuffix: false,
			explanationText: translatePlaceholder('FE_' + Features.GENERATE_AI_PRESENTATIONS, { maxAiPresentationCount: Number(planInfo?.maxAiPresentationCount) })
		},
		[Features.EXPORT_PRESENTATION_POWERPOINT]: {
			friendlyName: translatePlaceholder('FT_' + Features.EXPORT_PRESENTATION_POWERPOINT),
			addPremiumSuffix: false,
			explanationText: translatePlaceholder('FE_' + Features.EXPORT_PRESENTATION_POWERPOINT),
		},
		[Features.ALL_AI_LANGUAGES]: {
			friendlyName: translatePlaceholder('FT_' + Features.ALL_AI_LANGUAGES),
			addPremiumSuffix: false,
			explanationText: translatePlaceholder('FE_' + Features.ALL_AI_LANGUAGES),
			continueButtonText: <ContinueInEnglish />,
		},
		[Features.UNLIMITED_AI_SLIDES]: {
			friendlyName: translatePlaceholder('FT_' + Features.UNLIMITED_AI_SLIDES),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.UNLIMITED_AI_SLIDES, { maxPresentationAiSlidesCount: Number(planInfo?.maxPresentationAiSlidesCount) })
		},
		[Features.CUSTOM_PRESENTATION_LOGO]: {
			friendlyName: translatePlaceholder('FT_' + Features.CUSTOM_PRESENTATION_LOGO),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.CUSTOM_PRESENTATION_LOGO),
		},
		[Features.GENERATE_AI_CONCLUSION]: {
			friendlyName: translatePlaceholder('FT_' + Features.GENERATE_AI_CONCLUSION),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.GENERATE_AI_CONCLUSION),
		},
		[Features.PLAY_PRESENTATION]: {
			friendlyName: translatePlaceholder('FT_' + Features.PLAY_PRESENTATION),
			addPremiumSuffix: true,
			explanationText: translatePlaceholder('FE_' + Features.PLAY_PRESENTATION),
		}
	}), [planInfo]);

	/**
	 * Returns a list of missing features for given slides
	 * @param slides
	 */
	const getMissingFeatures = (slides: SlideList) : Array<string> => {

		const presentationFeatures = collectPresentationFeatures(slides);

		const missingFeatures = new Set<string>();

		presentationFeatures.forEach((feature: string) => {

			if (!(availableFeatures || []).includes(feature)) {

				missingFeatures.add(feature);
			}
		})

		return Array.from(missingFeatures);
	}

	/**
	 * Returns a list of features required for the given slides
	 *
	 * @param slides
	 */
	const collectPresentationFeatures = (slides: SlideList) => {

		const presentationFeatures = new Set();

		const pointsSlides = Object.values(slides).filter(slide => slide.type === SlideTypes.Points);

		const quizSlides = Object.values(slides).filter(slide => slide.type === SlideTypes.Quiz);

		const slidePropertiesList = Object.values(slides).map(slide => getSlidePropertiesByType(slide.type));

		const contentSlides = slidePropertiesList.filter(slideProperties => slideProperties.baseType === BaseTypes.Content);

		const quizSlidesWithLeaderboardEnabled = quizSlides.filter(slide => (slide.type === SlideTypes.Leaderboard));

		const nonQuizSlidesLength = Object.keys(slides).length - quizSlides.length - contentSlides.length;

		const slidesWithMultipleAllowedResponses = Object.values(slides).filter(slide => slide.nrOfVotes > 1);

		if(quizSlidesWithLeaderboardEnabled.length) {

			presentationFeatures.add(Features.LEADERBOARDS_BETWEEN_SLIDES);
		}

		if (slidesWithMultipleAllowedResponses.length) {

			presentationFeatures.add(Features.MULTIPLE_VOTE);
		}

		if (pointsSlides.length) {

			presentationFeatures.add(Features.POINTS_QUESTION);
		}

		if (quizSlides.length > (planInfo as PlanInfo)?.maxQuizQuestionsCount) {

			presentationFeatures.add(Features.UNLIMITED_SLIDES);
		}

		if (nonQuizSlidesLength > (planInfo as PlanInfo)?.maxQuestionsCount) {

			presentationFeatures.add(Features.UNLIMITED_SLIDES);
		}

		return Array.from(presentationFeatures);
	}

	return {
		isFeatureAvailable,
		getFeatureFriendlyName,
		getFeatureProps,
		getMissingFeatures,
		hasExceededSlideLimit,
	}
}